<template>
  <div class="component-historique">
    <div
      :class="{ 'btn-width': styleMenuBtn }"
      v-if="permission && !awesome"
      @click.prevent.stop="openModal('HistoriqueFacture' + index)"
      class="action-title"
    >
      {{ title }}
    </div>
    <div
      v-if="permission && awesome"
      class="block-icon-table-facture"
      :class="{ 'block-icon-popover-facture ': oblige }"
      @click.prevent.stop="openModal('HistoriqueFacture' + index)"
      title="Historique Facture"
    >
      <font-awesome-icon icon="file-invoice" />
    </div>
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-show"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Historique Facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('HistoriqueFacture' + index)"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text">
          <div content-class="mt-3" fill class="mt-3 tabs-class-historique">
            <div :title="'Fil dactualité  ' + computedCountLogs" active>
              <div v-if="dataToUse && dataToUse.length">
                <v-row
                  v-for="(hist, index) in dataToUse"
                  :key="index"
                  class="flex-div"
                >
                  <v-col cols="2" class="">
                    <div class="bloc-historique">
                      {{ hist.user }}
                    </div></v-col
                  >
                  <div class="">-</div>
                  <v-col cols="2" class="">
                    <div class="bloc-historique">
                      {{ hist.date }}
                    </div>
                  </v-col>
                  <v-col
                    cols="7"
                    class=""
                    style="text-align: initial"
                    v-if="!hist.attribute.includes('color')"
                    >{{ hist.message }}
                  </v-col>
                  <v-col cols="7" class="" style="text-align: initial" v-else>
                    Changement de couleur d'objet de facture de
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width-custom ml-2"
                      v-model="hist.old_value"
                      disabled
                    />
                    à
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width-custom ml-2"
                      v-model="hist.new_value"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <p class="empty-text">
                  Il n'y a aucune historique à afficher pour cette facture
                </p>
              </div>
            </div>
          </div>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent="hideModal('HistoriqueFacture' + index)"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputColorPicker from 'vue-native-color-picker';
export default {
  props: {
    permission: { required: true },
    dataToUse: { required: true },
    index: { required: true },
    awesome: { required: false },
    styleMenuBtn: { required: false },
    oblige: { required: false },
    title: { default: 'Historique Facture' }
  },
  data() {
    return { dialog: false, error: false };
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedCountLogs() {
      return this.dataToUse?.length != 0
        ? '(' + this.dataToUse?.length + ')'
        : '';
    }
  },
  methods: {
    hideModal() {
      this.error = false;
      this.dialog = false;
      // this.$emit('returnHistorique', false);
    },
    openModal() {
      this.error = false;
      this.dialog = true;
      // this.$emit('returnHistorique', true);
    }
  },
  components: {
    'v-input-colorpicker': InputColorPicker
  }
};
</script>
<style lang="scss" scoped>
.component-historique {
  text-align: -webkit-center !important;
}
.button-no-style {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 0;
  &.btn-width {
    width: 100%;
    border-radius: 10px;
    &:hover {
      background-color: #9799d6;
      color: #fff;
    }
  }
}
</style>
<style lang="scss">
.empty-text {
  color: #704ad1 !important;
  font-family: 'Montserrat' !important;
  font-size: 16px;
}
.bloc-historique {
  color: #704ad1;
  border: 1px solid #f1edfb;
  background-color: #f1edfb;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}
.tabs-class-historique {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
.cutsom-modal-bootstrap-historique .modal-dialog {
  top: 1% !important;
  max-width: 80% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-historique .modal-dialog {
    top: 1%;
    max-width: 80%;
    margin: auto;
  }
}
.color_picker_width-custom {
  width: 19px !important;
  height: 19px !important;
}
</style>
